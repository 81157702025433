import React from "react";
import { Link, Outlet } from "react-router-dom";
import mobile from "../../assets/mobilephone.png";
import light from "../../assets/ishiq.png";
import wifi from "../../assets/wifi.png";
import tv from "../../assets/tv.png";
import payment from "../../assets/paymentt.png";
import game from "../../assets/game.png";
import bank from "../../assets/banking.png";
import insurance from "../../assets/insurance.png";
import edu from "../../assets/edu.png";
import store from "../../assets/magaza.png";
import telephone from "../../assets/telephone.png";
import other from "../../assets/other.png";
const services = [
  {
    id: 1,
    name: "Mobil  Operatorlar",
    img: mobile,
  },
  {
    id: 2,
    name: "Kommunal  Ödənişlər",
    img: light,
  },
  {
    id: 3,
    name: "İnternet  Provayderlər",
    img: wifi,
  },
  {
    id: 4,
    name: "TV Provayderlər",
    img: tv,
  },
  {
    id: 5,
    name: "Ödəniş Sistemləri",
    img: payment,
  },
  {
    id: 6,
    name: "Əyləncə Xidmətləri",
    img: game,
  },
  {
    id: 7,
    name: "Bank Ödənişləri",
    img: bank,
  },
  {
    id: 8,
    name: "Sığorta",
    img: insurance,
  },
  {
    id: 9,
    name: "Təhsil",
    img: edu,
  },
  {
    id: 10,
    name: "Mağazalar Şəbəkəsi",
    img: store,
  },
  {
    id: 11,
    name: "Telefon Şəbəkəsi",
    img: telephone,
  },
  {
    id: 12,
    name: "Digər Xidmətlər",
    img: other,
  },
];
const Payment = () => {
  return (
    <>
       <div className="max-w-[90%] grid grid-cols-2 grid-rows-5 md:max-w-[60%]  2xl:max-w-[70%] md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4  mx-auto  mt-16 justify-between gap-5">
        {
          services.map((service)=>(
        <Link to={"operators"}>
        <div className="bg-[#F2F2F2] rounded-3xl min-h-[12vh] md:min-h-[22vh] flex flex-col items-center justify-center cursor-pointer mt-4  border border-[#DBDBDB]">
          <img className="h-8 md:h-12" src={service.img} alt="" />
          <p className="text-[#4F2D7E] text-xs md:text-sm 2xl:text-[1rem] font-semibold pt-2 text-center "> {service.name}  </p>
        </div>
        </Link>
          ))
        }
             
      </div>

      <Outlet />
    </>
  );
};

export default Payment;
