import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
const MobilePay1 = () => {
  let navigate=useNavigate();
  let params=useParams();
  return (
    <>
      <div>
        <div className="max-w-[90%] md:max-w-[50%] mx-auto bg-[#FAFAFA] shadow mt-10">
          <div className=" relative flex flex-col md:max-w-[30%] mx-auto  ">
            {/*header*/}

            <div className="flex items-center justify-center p-5 ">
              <div className="flex justify-center items-center">
                <h3 className="text-xl text-center font-bold">Bakcell</h3>
              </div>
            </div>
            <div>
              {" "}
              <h5 className="text-center text-[#2C2C2C] text-xl font-semibold">
                Kart məlumatlarınızı daxil edin
              </h5>
            </div>
            {/*body*/}
            <div className="relative  px-6 flex flex-col">
              <div className="py-12 mb-6">
                <input
                  type="text"
                  className=" text-black rounded-lg px-4 mb-10 py-2 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Kart nömrəsi"
                />
                <div className="flex gap-4">
                  <input
                    type="text"
                    className=" text-black rounded-lg px-4 mb-10 py-2 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                    placeholder="MM/YY"
                  />{" "}
                  <input
                    type="text"
                    className=" text-black rounded-lg px-4 mb-10 py-2 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                    placeholder="CVV2"
                  />
                </div>
                <div class="flex items-center">
                  <input
                    default-checkbox
                    id="checked-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded "
                  />
                  <label
                    for="checked-checkbox"
                    class="ml-2 text-sm font-semibold text-[#2C2C2C]"
                  >
                    Kartı yadda saxla{" "}
                  </label>
                </div>

                <div className="flex flex-col mb-10 mt-6">
                  <span className="text-[#9A9A9A] text-md">
                    Sifariş məbləği
                  </span>
                  <p className="text-[#1E1E1E] font-semibold text-md">
                    2.00 AZN{" "}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="text-[#9A9A9A] text-md">
                    Sifariş nömrəsi
                  </span>
                  <p className="text-[#1E1E1E] font-semibold text-md">
                    23456756{" "}
                  </p>
                </div>
              </div>

              <div className="flex flex-col md:flex md:flex-row md:items-center md:justify-end mb-1  p-6 ">
                <button
                  className="bg-transparent text-black font-semibold text-md px-6 py-1 rounded-xl  outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                  type="button"
                  onClick={()=>{
                    navigate(-1)
                  }}
                >
                  İmtina et
                </button>
                <button
                  className=" text-[#B3B3B3] md:w-28 bg-[#E4E4E4] font-semibold rounded-lg  text-md px-6 py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={()=>{
                    navigate("mobile3")
                  }}
                >
                  Ödə
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet/>
    </>
  );
};

export default MobilePay1;
