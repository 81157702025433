import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";

import Operators from "./components/mobileoperators/Operators";
import Dashboard from "./pages/dashboard/Dashboard"
import Payment from "./components/registeredpayments/Payment";
import Privateİnfo from "./components/PrivateInfo";
import Cards from "./components/Cards";
import History from "./components/History";
import Templates from "./components/Templates";
import Bonuses from "./components/Bonuses";
import Logout from "./components/Logout";
import MobilePay from "./components/mobileoperators/MobilePay"
import MobilePay1 from "./components/mobileoperators/MobilePay1"
import MobilePay2 from "./components/mobileoperators/MobilePay2"
import MobilePay3 from "./components/mobileoperators/MobilePay3"
import Info from "./components/info/Info"
import UnregisteredPayment from "./components/unregisteredpayment/UnregisteredPayment"

function App() {
  return (


    <BrowserRouter>
      <Routes>
        <Route  path="/" element={<Home />}>
        
          <Route index element={<UnregisteredPayment />} />           
          <Route path="operators">
            <Route index element={<Operators />}/>
           <Route  path=":operatorId" >
           <Route index element={<MobilePay />}/>
            <Route path=":mobile1">
            <Route index element={<MobilePay1 />}/>
              <Route path=":mobile2">
              <Route index element={<MobilePay2 />}/>
                <Route path=":mobile3" element={<MobilePay3 />} />
              </Route>
            </Route>
          </Route>
          </Route>
          </Route>
         
     
        {/* dashboard */}
        <Route  path="dashboard" element={<Dashboard />}>
          <Route >
          <Route index element={<Payment />}/>
          <Route path="operators">
            <Route index element={<Operators />}/>
           <Route  path=":operatorId" >
           <Route index element={<MobilePay />}/>
            <Route path=":mobile1">
            <Route index element={<MobilePay1 />}/>
              <Route path=":mobile2">
              <Route index element={<MobilePay2 />}/>
                <Route path=":mobile3" element={<MobilePay3 />} />
              </Route>
            </Route>
          </Route>
          </Route>
          </Route>
          <Route path="privateinfo" element={<Privateİnfo />} />
          <Route path="cards" element={<Cards />} />
          <Route path="history" element={<History />} />
          <Route path="templates" element={<Templates />} />
          <Route path="logout" element={<Logout />} />
          <Route path="bonuses" element={<Bonuses />} />
        </Route>
      </Routes>
      
      
    
    </BrowserRouter>


  );
}

export default App;
