import React, { Fragment, useState } from "react";
import { HiPlusSm } from "react-icons/hi";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Listbox, Transition } from "@headlessui/react";
import { Toaster, toast, resolveValue } from "react-hot-toast";
import "flowbite";
const people = [
  {
    id: 1,
    name: "Visa",
  },
  {
    id: 2,
    name: "Master",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TailwindToaster = () => {
  return (
    <Toaster>
      {(t) => (
        <Transition
          position="bottom"
          appear
          show={t.visible}
          className="transform p-4  bg-[#F0FFF8] text-[#BBDEA1] border border-[#BBDEA1] rounded shadow-lg md:px-[300px]"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <div className="flex justify-start">
            <p className="text-lg  md:ml-[-280px]">{resolveValue(t.message)}</p>
          </div>
        </Transition>
      )}
    </Toaster>
  );
};
const Cards = () => {
  const [selected, setSelected] = useState(people[0]);
  const [showTemplate, setShowTemplate] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showTemplate ? (
        <div className="flex flex-col items-center justify-center mt-32">
          <h1 className="text-[24px] md:text-[28px]">Yeni şablon</h1>
          <div>
            <div className="mb-8">
              <label className=" text-[19px] text-black " htmlFor="">
                Kartın adı
              </label>
              <div>
                <form class="flex items-center">
                  <div class="relative w-full">
                    <input
                      type="text"
                      id="simple-search"
                      class=" focus:outline-none focus:ring focus:ring-[#C9C9C9] bg-white border border-[#C9C9C9] text-gray-900 text-lg rounded-lg block w-full pl-4 px-[132px] py-3  font-semibold "
                      placeholder=""
                      required
                    />
                  </div>
                </form>
              </div>
            </div>
            <div>
              <label className="text-[19px]  text-black" htmlFor="">
                Plastik kart
              </label>

              <Listbox value={selected} onChange={setSelected}>
                {({ open }) => (
                  <>
                    <div className="mt-1 relative ">
                      <Listbox.Button className="relative w-full text-black text-xl bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-3  text-left cursor-pointer sm:text-sm">
                        <span className="flex items-center">
                          <span className="text-lg ml-3 block truncate">
                            {selected.name}
                          </span>
                        </span>
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ChevronDownIcon
                            className="h-6 w-6 text-[#FBB040]"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-[#ECECEC] scrollbar-thumb-rounded-full scrollbar-track-rounded-full absolute z-10 mt-1 w-full bg-white text-black shadow-lg max-h-56 rounded-md py-1  ring-1 ring-red-600 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {people.map((person) => (
                            <Listbox.Option
                              key={person.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-lg text-black bg-white"
                                    : "text-black",
                                  "cursor-pointer select-none relative py-2 pl-3 pr-9 text-lg"
                                )
                              }
                              value={person}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {person.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? "text-white" : "text-white",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              <div className="py-12 mb-6">
                <label className="text-[19px]  text-black" htmlFor="">
                  Kart nömrəsi
                </label>
                <input
                  type="text"
                  class="mb-8 focus:outline-none focus:ring focus:ring-[#C9C9C9] bg-white border border-[#C9C9C9] text-gray-900 text-lg rounded-lg block w-full pl-4 py-3  font-semibold "
                  placeholder="Kart nömrəsi"
                />
                <div className="flex gap-4">
                  <div>
                    <label className="text-[19px]  text-black" htmlFor="">
                      Ay/ il
                    </label>
                    <input
                      type="text"
                      className=" text-black rounded-lg px-4 mb-10 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                      placeholder="MM/YY"
                    />{" "}
                  </div>
                  <div>
                    <label className="text-[19px]  text-black" htmlFor="">
                      CVV
                    </label>
                    <input
                      type="text"
                      className=" text-black rounded-lg px-4 mb-10 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                      placeholder="CVV2"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end mb-1  p-6 ">
                <button
                  className="bg-transparent text-black font-semibold text-md px-6 py-1 rounded-xl  outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                  type="button"
                >
                  Geri
                </button>
                <button
                  className=" text-[#B3B3B3] w-28 bg-[#E4E4E4] font-semibold rounded-lg  text-md px-6 py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  Əlavə et
                </button>
              </div>
              {/* Modal */}
              {showModal ? (
                <>
                  <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative  my-6  ">
                      {/*content*/}
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-center justify-center p-5 ">
                          <div className=" ">
                            <h3 className="text-3xl text-center font-bold mb-7">
                              Leobank
                            </h3>
                            <span className="text-sm text-[#868686]">
                              We have sent a code to your phone +********3456 to
                              confirm the payment, enter it in the field below
                            </span>
                          </div>
                        </div>
                        <div className="">
                          <button
                            onClick={() => setShowModal(false)}
                            className="absolute top-0 right-2 p-1 ml-auto bg-transparent border-0 text-[#A9A9A9] opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          >
                            <span className="text-[#A9A9A9]">c</span>
                          </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex flex-col">
                          <input
                            type="text"
                            className="text-black rounded-lg px-4 py-3 border border-[#DBDBDB] mb-2 w-full placeholder:text-black"
                            placeholder="23456"
                          />
                        </div>
                        {/*footer*/}

                        <div className="flex flex-col items-center justify-center p-6 ">
                          <button
                            className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E] font-semibold  text-md px-8 py-1 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                          >
                            Davam et
                          </button>
                          <button
                            className=" text-black font-semibold  text-md px-6 py-1 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                          >
                            Yenidən göndər
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center ">
          <div className="">
            <h1 className="text-black text-[25px] md:text-[30px] mb-8 mt-8 text-center">
              Sizin yaddaşda kartınız yoxdur
            </h1>
            <div className="flex justify-center">
              <button
                onClick={() => setShowTemplate(true)}
                className="bg-[#4F2D7E] text-white text-[18px] py-2 px-8 flex items-center justify-center rounded-lg font-semibold"
              >
                Kart əlavə et <HiPlusSm className="ml-2 text-[25px] md:text-[31px]" />
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-20 md:max-w-[50%] mx-auto">
        <div className="flex justify-between items-center">
          <h1 className="text-black text-[15px] md:text-2xl font-semibold">
            Yaddaşa verilən kartlar
          </h1>
          <button
            className=" text-[#FBB040] text-[15px] md:text-[18px] md:py-2 md:px-8 flex items-center justify-center rounded-lg font-semibold"
            onClick={() => toast.success("Kart əlavə olundu")}
          >
           <span>Kart əlavə et</span>  <HiPlusSm className="ml-2 md:text-[31px]" />
          </button>
          <TailwindToaster />
        </div>
        <div className="mt-10">
          <div class=" bg-[#FAFAFA] shadow-md rounded-3xl p-6">
            <div className=" md:w-[72%] flex justify-between py-3">
              <p className="md:text-xl">Əlavə olunan tarix</p>
              <p className="md:text-xl">Kart</p>
            </div>
            <div className="border  border-[#EBEBEB]"></div>
            <div className="flex justify-between md:py-3 items-center">
              <div>
                <span className="text-[#AEAEAE] mr-1 md:mr-4 text-xs">20.06.22</span>
                <span className="text-[#AEAEAE] text-xs">16:05</span>
              </div>
              <div className="flex justify-between gap-4 md:gap-32">
                <div className="flex justify-between items-center gap-3 md:gap-10">
                  <span className="text-black text-xs font-semibold">
                    2323********5645
                  </span>
                  <p className="text-xs text-[#AEAEAE]">ABB</p>
                </div>
                <div className="flex items-center justify-center">
                  <button
                    id="dropdownMenuIconButton"
                    data-dropdown-toggle="dropdownDots"
                    class="inline-flex items-center md:p-2 md:text-sm font-medium text-center text-[#AEAEAE] bg-white rounded-lg  "
                    type="button"
                  >
                    <svg
                      class="w-4 h-4 md:w-6 md:h-6"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                    </svg>
                  </button>
                  <div
                    id="dropdownDots"
                    class="hidden z-10 md:w-36  bg-white rounded divide-y divide-gray-100 shadow border border-[#FBB040]"
                  >
                    <ul
                      class="md:py-1 md:text-md "
                      aria-labelledby="dropdownMenuIconButton"
                    >
                      <li>
                        <a href="#hl" class="block py-2 px-4 text-black">
                          Düzəliş etmək
                        </a>
                      </li>
                      <li>
                        <a href="#k" class="block py-2 px-4 text-black">
                          Silmək
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
