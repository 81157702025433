import React from 'react'
import kpay from "../../assets/kpay.png"
import {Link, Outlet,useParams} from "react-router-dom"
const operators = [
  {
    id: 1,
    name: "Kashpay",
    img:kpay,
  },
  {
    id: 2,
    name: "Kashpay",
    img:kpay,

  },
  {
    id: 3,
    name: "Kashpay",
    img:kpay,

  },
 
  
];
const Operators = () => {
  const params =useParams();
  return (
    <>
     <div>
      <div className='max-w-[90%] md:max-w-[75%] mt-2 mx-auto'>
      <nav class="shadow p-6 flex bg-white border-b-2 border-[#F2F2F2];" aria-label="Breadcrumb">
  <ol class="inline-flex items-center space-x-1 md:space-x-3">
    <li class="inline-flex items-center">
      <Link to={"/"} class="inline-flex items-center md:text-lg text-[#A1A1A1]">
        Ana səhifə
      </Link>
    </li>
    <li>
      <div class="flex items-center">
      <svg  class="md:w-5 md:h-5 text-gray-400" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.875 6.85796H3.5325L7.61625 1.87555C7.80721 1.64218 7.89908 1.34131 7.87165 1.03914C7.84422 0.736965 7.69974 0.458234 7.47 0.264265C7.24026 0.0702951 6.94407 -0.0230245 6.64659 0.00483557C6.34911 0.0326956 6.07471 0.179453 5.88375 0.412823L0.25875 7.26935C0.220906 7.32389 0.187064 7.38118 0.1575 7.44076C0.1575 7.4979 0.1575 7.53219 0.0787502 7.58932C0.0277579 7.72035 0.00105876 7.85983 0 8.00071C0.00105876 8.1416 0.0277579 8.28108 0.0787502 8.41211C0.0787502 8.46924 0.0787499 8.50353 0.1575 8.56066C0.187064 8.62025 0.220906 8.67754 0.25875 8.73208L5.88375 15.5886C5.98952 15.7176 6.12198 15.8213 6.2717 15.8924C6.42142 15.9635 6.58473 16.0003 6.75 16C7.01286 16.0005 7.2676 15.9075 7.47 15.7372C7.58392 15.6412 7.67808 15.5234 7.7471 15.3905C7.81612 15.2575 7.85864 15.112 7.87223 14.9623C7.88581 14.8127 7.8702 14.6617 7.82627 14.5182C7.78235 14.3747 7.71098 14.2414 7.61625 14.1259L3.5325 9.14347H16.875C17.1734 9.14347 17.4595 9.02307 17.6705 8.80876C17.8815 8.59446 18 8.30379 18 8.00071C18 7.69764 17.8815 7.40697 17.6705 7.19267C17.4595 6.97836 17.1734 6.85796 16.875 6.85796Z" fill="#363B39"/>
</svg>
        <a href="#l" class="ml-2 md:text-lg  md:ml-2 text-[#A1A1A1]">Mobil Operatorlar</a>
      </div>
    </li>
   
  </ol>
</nav>

      </div>
      <div className='max-w-[90%] md:max-w-[60%] 2xl:max-w-[45%] mx-auto grid grid-cols-2 gap-5 xl:grid-cols-3  justify-between py-40' >
     
      {
        operators.map((operator)=>(
          <Link to={`${operator.id}`}>
           <div className='bg-[#FAFAFA] rounded-3xl py-8  border border-[#DBDBDB] cursor-pointer' key={operator.id}>
           <div className='flex justify-center'>
           <img  src={operator.img} className='h-8 2xl:h-12' alt="" />
           </div>
            <div className='border-b border-[#DBDBDB] max-w-[60%] mx-auto my-2'></div>
            <p className='text-center text-[17px]  md:text-xl '>{operator.name}</p>
          </div>
          {}
          </Link>                         
        )
        )
      }
      </div>
     </div>
      <Outlet/>
    </>
  )
}

export default Operators