import React, { Fragment, useState } from "react";
import { HiPlusSm } from "react-icons/hi";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Listbox, Transition } from "@headlessui/react";

const people = [
  {
    id: 1,
    name: "Bakı",
  },
  {
    id: 2,
    name: "Gəncə",
  },
  {
    id: 3,
    name: "Sumqayıt",
  },
  {
    id: 4,
    name: "Mingəçevir",
  },
  {
    id: 5,
    name: "Qəbələ",
  },
  {
    id: 6,
    name: "İsmayıllı",
  },
  {
    id: 6,
    name: "Qusar",
  },
  {
    id: 6,
    name: "Quba",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Templates = () => {
  const [selected, setSelected] = useState(people[0]);
  const [showTemplate,setShowTemplate]=useState(false)
  return (
    <>
    {showTemplate ? (
      
       <div className="flex flex-col items-center justify-center mt-32">
                <h1 className="text-[24px] md:text-[28px]">Yeni şablon</h1>

        <div className="mb-8">
          <label className="text-[18px] md:text-[21px]  text-black" htmlFor="">Şablonun adı</label>
          <div>

          <form class="flex items-center">
            <label
              for="simple-search"
              class="sr-only text-[#C9C9C9] font-semibold text-lg"
            >
              Axtar
            </label>
            <div class="relative md:w-full">
             
              <input
                type="text"
                id="simple-search"
                class=" focus:outline-none focus:ring focus:ring-[#C9C9C9] bg-white border border-[#C9C9C9] text-gray-900 text-lg rounded-lg block w-full pl-4 px-[132px] py-3  font-semibold "
                placeholder="Yaz..."
                required
              />
            </div>
          </form>
        </div>
       
        </div>
        <div>
        <label className="text-[18px] md:text-[21px]  text-black" htmlFor="">Xidmət növü</label>

        <Listbox value={selected} onChange={setSelected}>
         {({ open }) => (
           <>
             <div className="mt-1 relative w-[300px]  md:w-[400px]">
               <Listbox.Button className="relative w-full text-black text-xl bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-3  text-left cursor-pointer sm:text-sm">
                 <span className="flex items-center">
                   <span className="text-lg ml-3 block truncate">
                     {selected.name}
                   </span>
                 </span>
                 <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                   <ChevronDownIcon
                     className="h-6 w-6 text-[#FBB040]"
                     aria-hidden="true"
                   />
                 </span>
               </Listbox.Button>

               <Transition
                 show={open}
                 as={Fragment}
                 leave="transition ease-in duration-100"
                 leaveFrom="opacity-100"
                 leaveTo="opacity-0"
               >
                 <Listbox.Options className="scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-[#ECECEC] scrollbar-thumb-rounded-full scrollbar-track-rounded-full absolute z-10 mt-1 w-full bg-white text-black shadow-lg max-h-56 rounded-md py-1  ring-1 ring-red-600 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                   {people.map((person) => (
                     <Listbox.Option
                       key={person.id}
                       className={({ active }) =>
                         classNames(
                           active
                             ? "text-lg text-black bg-white"
                             : "text-black",
                           "cursor-pointer select-none relative py-2 pl-3 pr-9 text-lg"
                         )
                       }
                       value={person}
                     >
                       {({ selected, active }) => (
                         <>
                           <div className="flex items-center">
                             <span
                               className={classNames(
                                 selected ? "font-semibold" : "font-normal",
                                 "ml-3 block truncate"
                               )}
                             >
                               {person.name}
                             </span>
                           </div>

                           {selected ? (
                             <span
                               className={classNames(
                                 active ? "text-white" : "text-white",
                                 "absolute inset-y-0 right-0 flex items-center pr-4"
                               )}
                             >
                               <CheckIcon
                                 className="h-5 w-5"
                                 aria-hidden="true"
                               />
                             </span>
                           ) : null}
                         </>
                       )}
                     </Listbox.Option>
                   ))}
                 </Listbox.Options>
               </Transition>
             </div>
           </>
         )}
       </Listbox>
        
        </div>
        
     </div>
    ):(
<div className="flex items-center justify-center ">
<div className="">
  <h1 className="text-black mt-8 text-[25px] md:text-[30px] mb-8 text-center">
    Sizin heç bir şablonunuz yoxdur
  </h1>
  <div className="flex justify-center">
    <button onClick={() => setShowTemplate(true)} className="bg-[#4F2D7E] text-white text-[18px] py-2 px-8 flex items-center justify-center rounded-lg font-semibold">
      Şablon yarat <HiPlusSm className="ml-2 text-[31px]" />
    </button>
  </div>
</div>
</div>
    )}
      
         <div className="md:max-w-[18%] mt-24 mx-auto flex justify-center md:justify-end">
            <button className="bg-[#E4E4E4] text-[#B3B3B3] px-16 py-3 rounded-xl text-lg">İrəli</button>
         </div>
     
    </>
  );
};

export default Templates;
