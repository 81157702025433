import React from 'react'

const PrivateInfo = () => {
  return (
    <>
    <div className="md:max-w-[50%] mx-auto   mt-10" >
          <div className="relative flex flex-col   ">
            {/*header*/}


            {/*body*/}
            <div className="relative  px-6 flex flex-col">
              <div className=" grid md:grid-cols-2 py-20 ">
               
               <div className='xl:mr-8 mb-8'>
               <label className="font-semibold text-black">Ad</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8  py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               <div >
               <label className="font-semibold text-black">Soyad</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               <div className='xl:mr-8 mb-8'>
               <label className="font-semibold text-black">Cins</label>
                <select
                  id="countries"
                  className="text-black rounded-lg px-4 py-4  border border-[#DBDBDB]  w-full placeholder:text-black"
                >
                  <option selected>kişi</option>
                  <option value="female">qadın</option>
                 
                </select>
               </div>
               <div> 
               <label className="font-semibold text-black">Doğum tarixi</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               <div className='xl:mr-8 mb-8'> 
               <label className="font-semibold text-black">E poçt</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               <div> 
               <label className="font-semibold text-black">Ünvan</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               <div className='xl:mr-8'> 
               <label className="font-semibold text-black">Telefon</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               <div> 
               <label className="font-semibold text-black">Şifrə</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               
              </div>

              <div className="flex items-center justify-end mb-1  p-6 ">
              
                <button
                  className=" text-[#B3B3B3] w-32 bg-[#E4E4E4] rounded-xl font-semibold  text-md  py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Yadda saxla
                </button>
              </div>
              <div className=" grid md:grid-cols-2 py-20 ">
               
               <div className='xl:mr-8 mb-8'>
               <label className="font-semibold text-black">Şifrə</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8  py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               <div >
              
               </div>
               <div className='xl:mr-8 mb-8'>
               <label className="font-semibold text-black">Yeni şifrə</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               <div> 
              
               </div>
               <div className='xl:mr-8 mb-8'> 
               <label className="font-semibold text-black">Yeni şifrənin təkrar</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-8 py-3 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
               </div>
               <div> 
               
               </div>
              
               
              </div>
              <div className="flex items-center justify-end mb-1  p-6 ">
              
                <button
                  className=" text-[#B3B3B3] w-32 bg-[#E4E4E4] rounded-xl font-semibold  text-md  py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Yadda saxla
                </button>
              </div>
            </div>
          </div>
        </div>


    </>
  )
}

export default PrivateInfo