import React from 'react'
import Header from "../components/header/Header"

import Info from "../components/info/Info"
import { Outlet } from 'react-router-dom'
import Footer from '../components/footer/Footer'


const Home = () => {
  return (
    <>
    <Header/> 
       <Outlet/>
       <Info/>
    <Footer/>
    </>
  )
}

export default Home