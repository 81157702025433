import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import InfiniteScroll from "react-infinite-scroll-component";
import location from "../../assets/location.png";
const people = [
  {
    id: 1,
    name: "Bakı",
  },
  {
    id: 2,
    name: "Gəncə",
  },
  {
    id: 3,
    name: "Sumqayıt",
  },
  {
    id: 4,
    name: "Mingəçevir",
  },
  {
    id: 5,
    name: "Qəbələ",
  },
  {
    id: 6,
    name: "İsmayıllı",
  },
  {
    id: 6,
    name: "Qusar",
  },
  {
    id: 6,
    name: "Quba",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Info = () => {
  const [selected, setSelected] = useState(people[0]);

  return (
    <>
      <div className="info mb-20 max-w-[90%]  md:max-w-[60%] mx-auto">
        

        <div className=" md:max-w-[80%] flex flex-col gap-4 mt-5">
        <h1 className="text-black text-2xl md:text-3xl font-semibold">Yeniliklər</h1>
          <div className="bg-[#FAF7F3] shadow-xl p-5 rounded-2xl">
            <p className="text-[#1E1E1E] md:text-lg">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit ofd r do{" "}
             
            </p>
            <span className="text-[#626262] md:text-lg pt-1">21.05.2022</span>
          </div>
          <div className="bg-[#FAF7F3] shadow-xl p-5 rounded-2xl">
            <p className="text-[#1E1E1E] md:text-lg">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit ofd r do{" "}
             
            </p>
            <span className="text-[#626262] md:text-lg pt-1">21.05.2022</span>
          </div>
          <div className="bg-[#FAF7F3] shadow-xl p-5 rounded-2xl">
            <p className="text-[#1E1E1E] text-lg">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit ofd r do{" "}
             
            </p>
            <span className="text-[#626262] text-lg pt-1">21.05.2022</span>
          </div>
          <div className="bg-[#FAF7F3] shadow-xl p-5 rounded-2xl">
            <p className="text-[#1E1E1E] text-lg">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit ofd r do{" "}
              
            </p>
            <span className="text-[#626262] text-lg pt-1">21.05.2022</span>
          </div>
        </div>
        <div className="space-y-6">
          <div>
            <h3 className="mt-4 text-black text-2xl xl:text-3xl font-semibold">
              Terminal xəritəsi
            </h3>
          </div>
          <div className="2xl:flex 2xl:gap-3">
            <div>
              <Listbox value={selected} onChange={setSelected}>
                {({ open }) => (
                  <>
                    <div className="mt-1 relative 2xl:w-[360px]">
                      <Listbox.Button className="relative w-full text-white text-xl bg-[#4F2D7E] border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2  text-left cursor-pointer sm:text-sm">
                        <span className="flex items-center">
                          <span className="text-lg ml-3 block truncate">
                            {selected.name}
                          </span>
                        </span>
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ChevronDownIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-purple-700 scrollbar-thumb-rounded-full scrollbar-track-rounded-full absolute z-10 mt-1 w-full bg-[#4F2D7E] text-white shadow-lg max-h-56 rounded-md py-1  ring-1 ring-red-600 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {people.map((person) => (
                            <Listbox.Option
                              key={person.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-lg text-white bg-[#4F2D7E]"
                                    : "text-white",
                                  "cursor-pointer select-none relative py-2 pl-3 pr-9 text-lg"
                                )
                              }
                              value={person}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {person.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? "text-white" : "text-white",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <div className="w-full">
              <div>
                <form class="flex items-center">
                  <input
                    type="text"
                    id="simple-search"
                    class="focus:outline-none placeholder:text-[#C9C9C9] placeholder:font-normal bg-white border border-[#C9C9C9] text-gray-900 text-lg rounded-lg block w-full mt-1  font-semibold "
                    placeholder="Küçə"
                    required
                  />
                </form>
              </div>
            </div>
          </div>
          <div>
            <img className="h-full w-full" src={location} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
