import React from "react";
import {AiOutlineSearch} from "react-icons/ai"
const Search = () => {
  return (
    <>
      <div className="md:max-w-[60%] w-full mx-auto xl:flex xl:justify-between">
        <div>
          <p className="hidden xl:block text-black text-xl xl:text-3xl font-bold">Ödəniş etmək</p>
        </div>
        <div>
          <form class="flex items-center">
            
            <div class="relative w-full">
              <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <AiOutlineSearch className="text-[#C9C9C9] text-2xl"/>
              </div>
              <input
                type="text"
                id="simple-search"
                className="focus:outline-none  bg-white border border-[#C9C9C9] text-gray-900 text-lg rounded-lg block w-full 
                placeholder:font-light  placeholder:text-[#C9C9C9] px-12 py-[9px]  font-semibold "
                placeholder="Axtar"
                required
              />
            </div>
          </form>
        </div>
      </div>

      <div className="mt-10 h-[2px] w-[60%] mx-auto bg-[#F2F2F2]"></div>
    </>
  );
};

export default Search;
