import React from 'react'
import Search from './services/Search'
import Service from './services/Service'
import ServiceBar from './services/ServiceBar'

const UnregisteredPayment = () => {
  return (
    <div className='mb-32'>
     
     <div>
      <ServiceBar/>
     </div>
     <div>
      <Service/>
     </div>

    </div>
  )
}

export default UnregisteredPayment