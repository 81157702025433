import React from "react";
import kpay from "../../../assets/kpay.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../../../styles.css";
import { Pagination, Autoplay } from "swiper";

const operators = [
  {
    id: 1,
    name: "Azercell",
    img: kpay,
  },
  {
    id: 2,
    name: "Bakcell",
    img: kpay,
  },
  {
    id: 3,
    name: "Nar",
    img: kpay,
  },
  {
    id: 4,
    name: "Nar",
    img: kpay,
  },
  {
    id: 5,
    name: "Nar",
    img: kpay,
  },
  {
    id: 6,
    name: "Nar",
    img: kpay,
  },
  {
    id: 7,
    name: "Nar",
    img: kpay,
  },
  {
    id: 8,
    name: "Nar",
    img: kpay,
  },
  {
    id: 9,
    name: "Nar",
    img: kpay,
  },
];

const ServiceBar = () => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 400,
  //    autoplay: true,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };
  return (
    <>
      <div className="max-w-[60%] mx-auto mt-5 ">
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          
          showsPagination={false}
          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {operators.map((operator) => (
            <SwiperSlide>
              <div className="flex justify-center items-center bg-[#FAFAFA] rounded-xl py-5 px-5  border border-[#DBDBDB] cursor-pointer pl-5">
                <img src={operator.img} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default ServiceBar;
