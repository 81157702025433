import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import bkcl from "../assets/bkcl.png";
import water from "../assets/water.png";
import light from "../assets/light.png";
import "tw-elements";

const people = [
  {
    id: 1,
    name: "Bakı",
  },
  {
    id: 2,
    name: "Gəncə",
  },
  {
    id: 3,
    name: "Sumqayıt",
  },
  {
    id: 4,
    name: "Mingəçevir",
  },
  {
    id: 5,
    name: "Qəbələ",
  },
  {
    id: 6,
    name: "İsmayıllı",
  },
  {
    id: 6,
    name: "Qusar",
  },
  {
    id: 6,
    name: "Quba",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const History = () => {
  const [selected, setSelected] = useState(people[0]);
return(
  <>
  </>
)
  // return (
  //   <>
  //     <div class="md:max-w-[65%] mx-auto">
  //       <div class="md:flex gap-8">
  //         <div>
  //           <Listbox value={selected} onChange={setSelected}>
  //             {({ open }) => (
  //               <>
  //                 <div className="mt-1 relative md:w-[306px]">
  //                   <Listbox.Button className="relative w-full text-black text-xl bg-[#FAFAFA] border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2  text-left cursor-pointer sm:text-sm">
  //                     <span className="flex items-center">
  //                       <span className="text-lg ml-3 block truncate">
  //                         {selected.name}
  //                       </span>
  //                     </span>
  //                     <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
  //                       <ChevronDownIcon
  //                         className="h-6 w-6 text-[#FBB040]"
  //                         aria-hidden="true"
  //                       />
  //                     </span>
  //                   </Listbox.Button>

  //                   <Transition
  //                     show={open}
  //                     as={Fragment}
  //                     leave="transition ease-in duration-100"
  //                     leaveFrom="opacity-100"
  //                     leaveTo="opacity-0"
  //                   >
  //                     <Listbox.Options className="scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-purple-700 scrollbar-thumb-rounded-full scrollbar-track-rounded-full absolute z-10 mt-1 w-full bg-white text-black shadow-lg max-h-56 rounded-md py-1  ring-1 ring-red-600 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
  //                       {people.map((person) => (
  //                         <Listbox.Option
  //                           key={person.id}
  //                           className={({ active }) =>
  //                             classNames(
  //                               active
  //                                 ? "text-lg text-black bg-white"
  //                                 : "text-black",
  //                               "cursor-pointer select-none relative py-2 pl-3 pr-9 text-lg"
  //                             )
  //                           }
  //                           value={person}
  //                         >
  //                           {({ selected, active }) => (
  //                             <>
  //                               <div className="flex items-center">
  //                                 <span
  //                                   className={classNames(
  //                                     selected
  //                                       ? "font-semibold"
  //                                       : "font-normal",
  //                                     "ml-3 block truncate"
  //                                   )}
  //                                 >
  //                                   {person.name}
  //                                 </span>
  //                               </div>

  //                               {selected ? (
  //                                 <span
  //                                   className={classNames(
  //                                     active ? "text-white" : "text-white",
  //                                     "absolute inset-y-0 right-0 flex items-center pr-4"
  //                                   )}
  //                                 >
  //                                   <CheckIcon
  //                                     className="h-5 w-5"
  //                                     aria-hidden="true"
  //                                   />
  //                                 </span>
  //                               ) : null}
  //                             </>
  //                           )}
  //                         </Listbox.Option>
  //                       ))}
  //                     </Listbox.Options>
  //                   </Transition>
  //                 </div>
  //               </>
  //             )}
  //           </Listbox>
  //         </div>
  //         <div>
  //           <Listbox value={selected} onChange={setSelected}>
  //             {({ open }) => (
  //               <>
  //                 <div className="mt-1 relative w-[306px]">
  //                   <Listbox.Button className="relative w-full text-black text-xl bg-[#FAFAFA] border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2  text-left cursor-pointer sm:text-sm">
  //                     <span className="flex items-center">
  //                       <span className="text-lg ml-3 block truncate">
  //                         {selected.name}
  //                       </span>
  //                     </span>
  //                     <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
  //                       <ChevronDownIcon
  //                         className="h-6 w-6 text-[#FBB040]"
  //                         aria-hidden="true"
  //                       />
  //                     </span>
  //                   </Listbox.Button>

  //                   <Transition
  //                     show={open}
  //                     as={Fragment}
  //                     leave="transition ease-in duration-100"
  //                     leaveFrom="opacity-100"
  //                     leaveTo="opacity-0"
  //                   >
  //                     <Listbox.Options className="scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-purple-700 scrollbar-thumb-rounded-full scrollbar-track-rounded-full absolute z-10 mt-1 w-full bg-white text-black shadow-lg max-h-56 rounded-md py-1  ring-1 ring-red-600 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
  //                       {people.map((person) => (
  //                         <Listbox.Option
  //                           key={person.id}
  //                           className={({ active }) =>
  //                             classNames(
  //                               active
  //                                 ? "text-lg text-black bg-white"
  //                                 : "text-black",
  //                               "cursor-pointer select-none relative py-2 pl-3 pr-9 text-lg"
  //                             )
  //                           }
  //                           value={person}
  //                         >
  //                           {({ selected, active }) => (
  //                             <>
  //                               <div className="flex items-center">
  //                                 <span
  //                                   className={classNames(
  //                                     selected
  //                                       ? "font-semibold"
  //                                       : "font-normal",
  //                                     "ml-3 block truncate"
  //                                   )}
  //                                 >
  //                                   {person.name}
  //                                 </span>
  //                               </div>

  //                               {selected ? (
  //                                 <span
  //                                   className={classNames(
  //                                     active ? "text-white" : "text-white",
  //                                     "absolute inset-y-0 right-0 flex items-center pr-4"
  //                                   )}
  //                                 >
  //                                   <CheckIcon
  //                                     className="h-5 w-5"
  //                                     aria-hidden="true"
  //                                   />
  //                                 </span>
  //                               ) : null}
  //                             </>
  //                           )}
  //                         </Listbox.Option>
  //                       ))}
  //                     </Listbox.Options>
  //                   </Transition>
  //                 </div>
  //               </>
  //             )}
  //           </Listbox>
  //         </div>
  //         <div>
  //           <Listbox value={selected} onChange={setSelected}>
  //             {({ open }) => (
  //               <>
  //                 <div className="mt-1 relative w-[306px]">
  //                   <Listbox.Button className="relative w-full text-black text-xl bg-[#FAFAFA] border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2  text-left cursor-pointer sm:text-sm">
  //                     <span className="flex items-center">
  //                       <span className="text-lg ml-3 block truncate">
  //                         {selected.name}
  //                       </span>
  //                     </span>
  //                     <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
  //                       <ChevronDownIcon
  //                         className="h-6 w-6 text-[#FBB040]"
  //                         aria-hidden="true"
  //                       />
  //                     </span>
  //                   </Listbox.Button>

  //                   <Transition
  //                     show={open}
  //                     as={Fragment}
  //                     leave="transition ease-in duration-100"
  //                     leaveFrom="opacity-100"
  //                     leaveTo="opacity-0"
  //                   >
  //                     <Listbox.Options className="scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-purple-700 scrollbar-thumb-rounded-full scrollbar-track-rounded-full absolute z-10 mt-1 w-full bg-white text-black shadow-lg max-h-56 rounded-md py-1  ring-1 ring-red-600 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
  //                       {people.map((person) => (
  //                         <Listbox.Option
  //                           key={person.id}
  //                           className={({ active }) =>
  //                             classNames(
  //                               active
  //                                 ? "text-lg text-black bg-white"
  //                                 : "text-black",
  //                               "cursor-pointer select-none relative py-2 pl-3 pr-9 text-lg"
  //                             )
  //                           }
  //                           value={person}
  //                         >
  //                           {({ selected, active }) => (
  //                             <>
  //                               <div className="flex items-center">
  //                                 <span
  //                                   className={classNames(
  //                                     selected
  //                                       ? "font-semibold"
  //                                       : "font-normal",
  //                                     "ml-3 block truncate"
  //                                   )}
  //                                 >
  //                                   {person.name}
  //                                 </span>
  //                               </div>

  //                               {selected ? (
  //                                 <span
  //                                   className={classNames(
  //                                     active ? "text-white" : "text-white",
  //                                     "absolute inset-y-0 right-0 flex items-center pr-4"
  //                                   )}
  //                                 >
  //                                   <CheckIcon
  //                                     className="h-5 w-5"
  //                                     aria-hidden="true"
  //                                   />
  //                                 </span>
  //                               ) : null}
  //                             </>
  //                           )}
  //                         </Listbox.Option>
  //                       ))}
  //                     </Listbox.Options>
  //                   </Transition>
  //                 </div>
  //               </>
  //             )}
  //           </Listbox>
  //         </div>

  //         <div>
  //           <button class="bg-[#4F2D7E] text-white py-2 px-6 rounded-lg font-semibold">
  //             Göstər
  //           </button>
  //         </div>
  //       </div>
  //       <div className="mt-20">
  //         <div className="mb-5">
  //           <label className="text-lg font-semibold" htmlFor="">
  //             Bugün
  //           </label>
  //         </div>
  //         <div>
  //           <div class="accordion" id="accordionExample">
  //             <div class="accordion-item bg-white border-b border-gray-200">
  //               <h2 class="accordion-header mb-0" id="headingOne">
  //                 <button
  //                   class="
  //       accordion-button
  //       relative
  //       flex
  //       items-center
  //       md:w-full
  //       py-4
  //       px-5
  //       text-base text-gray-800 text-left
  //       bg-white
  //       border-0
  //       rounded-none
  //       transition
  //       focus:outline-none
  //     "
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseOne"
  //                   aria-expanded="true"
  //                   aria-controls="collapseOne"
  //                 >
  //                   <div className="flex gap-[400px] items-center">
  //                     <div className="flex gap-5">
  //                       {/* <div className="flex justify-center   items-center border rounded-2xl shadow px-2">
  //                         <img
  //                           className="md:h-[80px] md:w-[65px] 2xl:w-full "
  //                           src={water}
  //                           alt="sd"
  //                         />
  //                       </div> */}
  //                       <div>
  //                         <p className="text-black text-[21px] font-semibold">
  //                           Azəriqaz
  //                         </p>
  //                         <span className="text-[#A2A9A9]">20 aprel 11:35</span>
  //                       </div>
  //                     </div>
  //                     <div>
  //                       <p className="text-[#A2A9A9] text-[20px]">
  //                         Kommunal ödənişlər
  //                       </p>
  //                     </div>
  //                      <div>
  //                       <p className="text-black text-[21px] font-semibold">
  //                         - 59.00 <span></span>{" "}
  //                       </p>
  //                      </div>
  //                   </div>
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseOne"
  //                 class="accordion-collapse collapse show"
  //                 aria-labelledby="headingOne"
  //                 data-bs-parent="#accordionExample"
  //               >
  //                 <div class="accordion-body py-4 px-5">
  //                   <div className="flex justify-between">
  //                     <div>
  //                       <p className="text-[19px] text-[#A1A1A1]">
  //                         Bonus:{" "}
  //                         <span className="text-black text-[18px]">0.03</span>
  //                       </p>
  //                     </div>
  //                     <div>
  //                       <p className="text-[19px] text-[#A1A1A1]">
  //                         Əməliyyat kodu:{" "}
  //                         <span className="text-black text-[18px]">
  //                           5263648769587458
  //                         </span>
  //                       </p>
  //                     </div>
  //                     <div>
  //                       <button className="bg-[#4F2D7E] text-white py-2 px-6 rounded-lg font-semibold">
  //                         Yenidən ödə
  //                       </button>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div class="accordion-item bg-white  border-b border-gray-200">
  //               <h2 class="accordion-header mb-0" id="headingTwo">
  //                 <button
  //                   class="
  //       accordion-button
  //       collapsed
  //       relative
  //       flex
  //       items-center
  //       w-full
  //       py-4
  //       px-5
  //       text-base text-gray-800 text-left
  //       bg-white
  //       border-0
  //       rounded-none
  //       transition
  //       focus:outline-none
  //     "
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseTwo"
  //                   aria-expanded="false"
  //                   aria-controls="collapseTwo"
  //                 >
  //                   <div className="flex gap-[400px] items-center">
  //                     <div className="flex gap-5">
  //                       {/* <div className="flex justify-center px-2 items-center border rounded-2xl shadow ">
  //                         <img
  //                           className="h-full w-full"
  //                           src={light}
  //                           alt=""
  //                         />
  //                       </div> */}
  //                       <div>
  //                         <p className="text-black text-[21px] font-semibold">
  //                           Azərişıq
  //                         </p>
  //                         <span className="text-[#A2A9A9]">20 aprel 11:35</span>
  //                       </div>
  //                     </div>
  //                     <div>
  //                       <p className="text-[#A2A9A9] text-[20px]">
  //                         Kommunal ödənişlər
  //                       </p>
  //                     </div>
  //                     <div>
  //                       <p className="text-black text-[21px] font-semibold">
  //                         - 59.00 <span></span>{" "}
  //                       </p>
  //                     </div>
  //                   </div>
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseTwo"
  //                 class="accordion-collapse collapse"
  //                 aria-labelledby="headingTwo"
  //                 data-bs-parent="#accordionExample"
  //               >
  //                 <div class="accordion-body py-4 px-5">
  //                   <div className="flex justify-between">
  //                     <div>
  //                       <p className="text-[19px] text-[#A1A1A1]">
  //                         Bonus:{" "}
  //                         <span className="text-black text-[18px]">0.03</span>
  //                       </p>
  //                     </div>
  //                     <div>
  //                       <p className="text-[19px] text-[#A1A1A1]">
  //                         Əməliyyat kodu:{" "}
  //                         <span className="text-black text-[18px]">
  //                           5263648769587458
  //                         </span>
  //                       </p>
  //                     </div>
  //                     <div>
  //                       <button className="bg-[#4F2D7E] text-white py-2 px-6 rounded-lg font-semibold">
  //                         Yenidən ödə
  //                       </button>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="mt-5">
  //               <label className="text-lg font-semibold" htmlFor="">
  //                 Dünən
  //               </label>
  //             </div>
  //             <div class="accordion-item bg-white border-b border-gray-200">
  //               <h2 class="accordion-header mb-0" id="headingThree">
  //                 <button
  //                   class="
  //       accordion-button
  //       collapsed
  //       relative
  //       flex
  //       items-center
  //       w-full
  //       py-4
  //       px-5
  //       text-base text-gray-800 text-left
  //       bg-white
  //       border-0
  //       rounded-none
  //       transition
  //       focus:outline-none
  //     "
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseThree"
  //                   aria-expanded="false"
  //                   aria-controls="collapseThree"
  //                 >
  //                   <div className="flex gap-[400px] items-center">
  //                     <div className="flex gap-5">
  //                       <div className="flex justify-center px-2 items-center border rounded-2xl shadow ">
  //                         <img
  //                           className="h-[80px] w-[65px]  "
  //                           src={bkcl}
  //                           alt=""
  //                         />
  //                       </div>
  //                       <div>
  //                         <p className="text-black text-[21px] font-semibold">
  //                           Bakcell
  //                         </p>
  //                         <span className="text-[#A2A9A9]">20 aprel 11:35</span>
  //                       </div>
  //                     </div>
  //                     <div>
  //                       <p className="text-[#A2A9A9] text-[20px]">
  //                         Mobil operatorlar
  //                       </p>
  //                     </div>
  //                     <div>
  //                       <p className="text-black text-[21px] font-semibold">
  //                         - 59.00 <span></span>{" "}
  //                       </p>
  //                     </div>
  //                   </div>
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseThree"
  //                 class="accordion-collapse collapse"
  //                 aria-labelledby="headingThree"
  //                 data-bs-parent="#accordionExample"
  //               >
  //                 <div class="accordion-body py-4 px-5">
  //                   <div className="flex justify-between">
  //                     <div>
  //                       <p className="text-[19px] text-[#A1A1A1]">
  //                         Bonus:{" "}
  //                         <span className="text-black text-[18px]">0.03</span>
  //                       </p>
  //                     </div>
  //                     <div>
  //                       <p className="text-[19px] text-[#A1A1A1]">
  //                         Əməliyyat kodu:{" "}
  //                         <span className="text-black text-[18px]">
  //                           5263648769587458
  //                         </span>
  //                       </p>
  //                     </div>
  //                     <div>
  //                       <button className="bg-[#4F2D7E] text-white py-2 px-6 rounded-lg font-semibold">
  //                         Yenidən ödə
  //                       </button>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default History;
