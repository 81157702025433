import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiOutlineGlobeAlt, HiOutlineX } from "react-icons/hi";
import {Link, useNavigate} from 'react-router-dom';
import logo from "../../assets/kpay.png"
import phone from "../../assets/phone.png"
import Search from "../unregisteredpayment/services/Search";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgetPswd, setShowForgetPswd] = useState(false);
  const [showPswdCode, setShowPswdCode] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showMobileCode, setShowMobileCode] = useState(false);
  const [passCode, setPassCode] = useState(false);

  


  const navigate = useNavigate();

  return (
    <div>
      <nav className="bg-white  py-5 rounded ">
        <div className=" max-w-[90%] md:max-w-[60%] flex flex-col md:flex-row md:flex-wrap md:justify-between md:items-center mx-auto">
          <Link to={"/"} class="flex items-center mb-5">
            <img
              src={logo}
              class="mr-3 h-8 md:h-12"
              alt="Kashlok Logo"
            />
          </Link>
          <div className="block md:hidden">
          <Search/>
          </div>
          <div className="flex flex-col gap-2  md:flex md:flex-row md:items-center md:order-2">
            <Link to={"dashboard"}>
            <button
              className="text-lg text-[#4F2D7E] order-2 md:order-1 border border-[#4F2D7E] hover:bg-[#4F2D7E] hover:text-white active:bg-[#4F2D7E] font-semibold px-8 py-3 rounded-xl outline-none focus:outline-none  mb-1 ease-linear transition-all duration-150 "
              type="button"
              onClick={() => setShowRegisterModal(true)}
            >
              Qeydiyyat
            </button>
            </Link>

           
           <Link to={"dashboard"}>
           <button
              type="button"
              class="text-lg text-white bg-[#4F2D7E]  font-semibold rounded-xl order-1 md:order-2  px-12 py-[14px]  mb-2"
              onClick={() => setShowModal(true)}            
            >
              Daxil ol
            </button>
           </Link>
           
          </div>
          {/* Login Modal */}

          {showModal ? (
            <>
              <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative  my-6 w-[360px] md:w-[420px] max-w-[450px]">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    {showForgetPswd ? (
                      <>
                        <div className="flex items-center justify-center p-5 ">
                          <div className="flex justify-center items-center">
                            <h3 className="text-xl text-center font-bold">
                              Şifrənizi unutmusunuz?
                            </h3>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center justify-center p-5 ">
                          <div className="flex justify-center items-center">
                            <h3 className="text-xl text-center font-bold">
                              Daxil Ol
                            </h3>
                          </div>
                        </div>
                      </>
                    )}
                    <div>
                      <button
                        className="absolute top-0 right-2 p-1 ml-auto bg-transparent border-0 text-[#A9A9A9] opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <HiOutlineX
                          className="text-[#3d3d3d]"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex flex-col">
                      {showForgetPswd ? (
                        <>
                          {showPswdCode ? (
                            <>
                              <div>
                                <p className="text-[#7C7C7C] text-center text-sm font-semibold mb-4">
                                  Mobil nömrənizə göndərilən SMS kodunu daxil
                                  edin.
                                </p>

                                <p className="text-[#FBB040] text-sm text-center mb-2">
                                  01:45
                                </p>
                                <input
                                  type="text"
                                  className="text-black rounded-lg px-4 py-1 border border-[#DBDBDB] mb-2 w-full placeholder:text-black"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <p className="text-[#7C7C7C] text-center text-sm font-semibold mb-4">
                                  Daxil edilən mobil nömrəyə SMS kod
                                  göndəriləcək. Mobil nömrəniz qeydiyyatdan
                                  keçdiyiniz nömrə ilə eyni olmalıdır.
                                </p>
                                <input
                                  type="text"
                                  className="text-black rounded-lg px-4 py-1 border border-[#DBDBDB] mb-2 w-full placeholder:text-black"
                                  placeholder="+994"
                                />
                              </div>
                            </>
                          )}
                        </>
                      ) : showLogin ? (
                        <div>
                          <input
                            type="text"
                            className="text-black rounded-lg px-4 py-1 border border-[#DBDBDB] mb-2 w-full placeholder:text-black"
                            placeholder="055 678 67 65"
                          />
                          <input
                            type="password"
                            className=" text-black rounded-lg px-4 py-1 border border-[#DBDBDB] w-full placeholder:text-black"
                            placeholder="************"
                          />
                        </div>
                      ) : (
                        <>
                          <button
                            onClick={() => setShowLogin(true)}
                            className="mb-2 text-[#B1B1B1] bg-white border border-[#DBDBDB] rounded-lg shadow-sm px-8 py-1"
                          >
                            Telefon
                          </button>
                          <button
                            onClick={() => setShowLogin(true)}
                            className="text-[#B1B1B1] bg-white border last:border-[#DBDBDB] rounded-lg shadow-sm px-8 py-1"
                          >
                            Şifrə
                          </button>
                        </>
                      )}
                    </div>
                    {/*footer*/}
                    {showForgetPswd ? (
                      <></>
                    ) : (
                      <div className="flex justify-end">
                        <button className="px-2 text-[#FBB040] text-sm" onClick={()=>setShowRegisterModal(true)}>
                          Qeydiyyat
                        </button>
                        <button
                          className="px-6 text-[#FBB040] text-sm"
                          onClick={() => setShowForgetPswd(true)}
                        >
                          Şifrəni unutmusunuz?
                        </button>
                      </div>
                    )}

                    {showForgetPswd ? (
                      <>
                        {showPswdCode ? (
                          <>
                            <div className="flex flex-col items-center justify-center mb-1  p-6 ">
                              <button
                                className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E] font-semibold w-28 text-md px-6 py-1 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowPswdCode(true)}
                              >
                                Təsdiqlə
                              </button>
                              <button
                                className=" text-black w-28 bg-transparent font-semibold  text-md px-6 py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => navigate(-1)}
                              >
                                Geri
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="flex flex-col items-center justify-center mb-1  p-6 ">
                            <button
                              className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E] font-semibold w-28 text-md px-6 py-1 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => setShowPswdCode(true)}
                            >
                              Göndər
                            </button>
                            <button
                              className=" text-black w-28 bg-transparent font-semibold  text-md px-6 py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => navigate(-1)}
                            >
                              Geri
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="flex items-center justify-center p-6 ">
                        <button
                          className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E] font-semibold  text-md px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowForgetPswd(true)}
                        >
                          Davam et
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}




        {/* Register Modal */}

        {showRegisterModal ? (
            <>
              <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative  my-6  w-[420px] max-w-[450px]">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                     {
                      passCode ? (
                        <>
                        <div className="flex items-center justify-center p-5 ">
                          <div className="flex justify-center items-center">
                            <h3 className="text-xl text-center font-bold">
                            Şifrə təyin edin
                            </h3>
                          </div>
                        </div>

                        </>
                      ) :(
                        <>
                        <div className="flex items-center justify-center p-5 ">
                          <div className="flex justify-center items-center">
                            <h3 className="text-xl text-center font-bold">
                            Qeydiyyat
                            </h3>
                          </div>
                        </div></>
                      )
                     }
                    
                
                    <div className="">
                      <button
                        className="absolute top-0 right-2 p-1 ml-auto bg-transparent border-0 text-[#A9A9A9] opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowRegisterModal(false)}
                      >
                        <HiOutlineX
                          className="text-[#3d3d3d]"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex flex-col">
                        {
                          passCode ? (
                            <>
                             <div>
                                <p className="text-[#7C7C7C] text-center text-sm font-semibold mb-4">
                                Qeydiyyatdan keçin və istənilən ödənişi
cəmi bir neçə saniyəyə edin
                                </p>
                                <div>
                                  <label htmlFor="" className="text-[#1E1E1E]">Şifrə</label>
                                  <input
                                  type="text"
                                  className="text-black rounded-lg px-4 py-1 border border-[#DBDBDB] mb-2 w-full placeholder:text-[#CECECE]"
                                  placeholder="*****"
                                />
                                </div>
                                <p className="text-[#7C7C7C] text-center text-sm font-semibold mb-4">Şifrə ən azı 8 simvoldan (min. 1 hərf və min. 1 rəqəm) ibarət olmalıdır</p>
                              </div>
                            </>
                          ):(
                            <>
                            {showMobileCode ? (
                              <> <div>
                              <input
                                type="text"
                                className="text-black rounded-lg px-4 py-1 border border-[#DBDBDB] mb-2 w-full placeholder:text-black"
                              />
                              <p className="text-[#7C7C7C] text-center text-sm font-semibold mb-4">
                                Mobil nömrənizə göndərilən SMS kodunu daxil
                                edin.
                              </p>
                          
                              <p className="text-[#FBB040] text-sm text-center mb-2">
                                25 san
                              </p>

                              
                            </div></>
                            ):(<>
                             <div>
                                <p className="text-[#7C7C7C] text-center text-sm font-semibold mb-4">
                                Qeydiyyatdan keçin və istənilən ödənişi
                                cəmi bir neçə saniyəyə edin
                                </p>
                                <div>
                                  <label htmlFor="" className="text-[#1E1E1E]">Mobil nömrə</label>
                                  <input
                                  type="text"
                                  className="text-black rounded-lg px-4 py-1 border border-[#DBDBDB] mb-2 w-full placeholder:text-[#CECECE]"
                                  placeholder="+994"
                                />
                                </div>
                                
                              </div></>) }
                           
                             
                       </>
                          )
                        }
                       
                         
                        
                       
                        
                      
                    </div>
                    {/*footer*/}
                    {
                      passCode ? (
                        <>
                        <div className="flex flex-col items-center justify-center mb-1  p-6 ">
                          <Link to={"/operators"}>
                          <button
                                className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E] font-semibold w-32 text-md px-6 py-1 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {setPassCode(true);setShowRegisterModal(false)}}
                              >
                               Qeydiyyat
                              </button>
                          </Link>
                             
                        </div>
                        </>
                      ) :(
                      <>
                       {showMobileCode ? (
                      <>
                        {showMobileCode ? (
                          <>
                            <div className="flex flex-col items-center justify-center mb-1  p-6 ">
                              <button
                                className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E] font-semibold w-32 text-md px-6 py-1 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setPassCode(true)}
                              >
                                Təsdiq et
                              </button>
                              <button
                                className=" text-[#A1A1A1]   bg-transparent font-semibold  text-md px-3 py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => navigate(-1)}
                              >
                                Yenidən göndər
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="flex flex-col items-center justify-center mb-1  p-6 ">
                            <button
                              className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E] font-semibold w-28 text-md px-6 py-1 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => setShowMobileCode(true)}
                            >
                              Göndər
                            </button>
                            
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="flex items-center justify-center p-6 ">
                        <button
                          className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E] font-semibold  text-md px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowMobileCode(true)}
                        >
                          Göndər
                        </button>
                      </div>
                    )}
                      </>)
                    }

                   
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}



          <div
            className="hidden justify-between items-center w-full md:flex md:w-auto md:order-1"
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex justify-center w-full   px-4 py-2 bg-white text-lg font-medium ">
                      <HiOutlineGlobeAlt
                        className=" h-7 w-7 mr-3 text-[#FBB040]"
                        aria-hidden="true"
                      />
                      AZE
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#s"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-lg"
                              )}
                            >
                              RU
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#s"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-lg"
                              )}
                            >
                              EN
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
              <li className="flex justify-center items-center">
                <span className="mr-2">
                  <img src={phone} alt="" />
                </span>
                <p className="text-xl">11 19</p>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="hidden md:block">
      <Search/>
     </div>
    </div>
  );
};

export default Header;
