import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
const MobilePay = () => {
  let navigate=useNavigate()
  return (
    <>
      <div>
        <div className="h-[64vh] max-w-[90%] md:max-w-[50%] mx-auto bg-[#FAFAFA] shadow mt-10" >
          <div className=" relative flex flex-col md:max-w-[30%] mx-auto  ">
            {/*header*/}

            <div className="flex items-center justify-center p-5 ">
              <div className="flex justify-center items-center">
                <h3 className="text-xl text-center font-bold">Bakcell</h3>
              </div>
            </div>

            {/*body*/}
            <div className="relative  px-6 flex flex-col">
              <div className="py-12 mb-6">
                <label
                  for="countries"
                  class="block mb-2 text-md font-semibold text-[#1E1E1E] "
                >
                  Prefiks
                </label>
                <select
                  id="countries"
                  className="text-black rounded-lg px-4 py-4  border border-[#DBDBDB] mb-20 w-full placeholder:text-black"
                >
                  <option selected>055</option>
                  <option value="US">051</option>
                  <option value="CA">050</option>
                  <option value="FR">070</option>
                </select>
               
                <label className="font-semibold  text-black">Telefon nömrəsi</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-4 py-2 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz..."
                />
              </div>

              <div className="flex flex-col md:flex md:flex-row md:items-center md:justify-end mb-1  p-6 ">
                <button
                  className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E] font-semibold md:w-28 text-md px-6 py-1 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                  type="button"
                  onClick={()=>{
                    navigate(-1)
                  }}
                >
                    Geri
                  
                </button>
                <button
                  className=" text-black  md:w-28 bg-transparent font-semibold  text-md px-6 py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={()=>{
                    navigate("mobile1")
                  }}
                >
                  İrəli
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet/>
    </>
  );
};

export default MobilePay;
