import React, { useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
const MobilePay1 = () => {
  // const [btnState,setBtnState]=useState(true)
  let navigate=useNavigate()
   let params=useParams()
  // const handleClick=()=>{
  //   setBtnState(btnState=>!btnState)
  // }

  // let toggleClassCheck = btnState ? "active":"";
  return (
    <>
         <div>
        <div className="max-w-[90%] md:max-w-[50%] mx-auto bg-[#FAFAFA] shadow mt-10" >
          <div className=" relative flex flex-col md:max-w-[30%] mx-auto  ">
            {/*header*/}

            <div className="flex items-center justify-center p-5 ">
              <div className="flex justify-center items-center">
                <h3 className="text-xl text-center font-bold">Bakcell</h3>
              </div>
            </div>

            {/*body*/}
            <div className="relative  px-6 flex flex-col">
              <div className="py-12 mb-6">
               
               
                <label className="font-semibold  text-black">Telefon nömrəsi</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-4 mb-10 py-2 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="055 234 78 56"
                />
                 <label
                  for="countries"
                  class="block mb-2 text-md font-semibold text-[#1E1E1E] "
                >
                  Plastik kart
                </label>
                <select
                  id="countries"
                  className="text-black rounded-lg px-4 py-3  border border-[#DBDBDB] mb-10 w-full placeholder:text-black"
                >
                  <option selected>Visa</option>
                  <option value="US">Visa</option>
                  <option value="CA">Visa</option>
                  <option value="FR">Visa</option>
                </select>
                <label className="font-semibold  text-black">Məbləğ</label>
                <input
                  type="text"
                  className=" text-black rounded-lg px-4 py-2 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="2"
                />
                <div className='flex items-center gap-2'> 
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 1.8C5.02355 1.8 1.8 5.02355 1.8 9C1.8 12.9765 5.02355 16.2 9 16.2C12.9765 16.2 16.2 12.9765 16.2 9C16.2 5.02355 12.9765 1.8 9 1.8ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9Z" fill="#FBB040"/>
<path d="M9 7.2C9.49706 7.2 9.9 7.60294 9.9 8.1V13.5C9.9 13.9971 9.49706 14.4 9 14.4C8.50294 14.4 8.1 13.9971 8.1 13.5V8.1C8.1 7.60294 8.50294 7.2 9 7.2Z" fill="#FBB040"/>
<path d="M10.35 4.95C10.35 5.69558 9.74558 6.3 9 6.3C8.25442 6.3 7.65 5.69558 7.65 4.95C7.65 4.20442 8.25442 3.6 9 3.6C9.74558 3.6 10.35 4.20442 10.35 4.95Z" fill="#FBB040"/>
</svg>
                       Məbləğ arası 1 - 800 AZN olmalıdır</div>
              </div>

              <div className="flex flex-col md:flex md:flex-row md:items-center md:justify-end mb-1  p-6 ">
                <button
                  className="bg-[#4F2D7E] text-white active:bg-[#4F2D7E]  font-semibold md:w-28 text-md px-6 py-1 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                  type="button"
                  onClick={()=>{
                    navigate(-1)
                  }}
                >
                    Geri
                  
                </button>
                <button
                id='btn'  className="text-black rounded-xl md:w-28 bg-transparent font-semibold hover:bg-[#4F2D7E] hover:text-white hover:rounded-xl text-md px-6 py-1  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all
                   duration-150" 
                  
                  type="button"
                  onClick={()=>{navigate("mobile2")}}
                >
                  İrəli
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet/>
    </>
  )
}

export default MobilePay1