import { Fragment, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { Menu, Transition } from "@headlessui/react";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import {
  AiOutlineHistory,
  AiOutlinePercentage,
  AiOutlineStar,
  AiOutlineSearch
} from "react-icons/ai";

import { IoWalletOutline, IoCardSharp, IoLogOutOutline } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import { Link, NavLink, Outlet } from "react-router-dom";
import "./Dashboard.css";
import logo from "../../assets/kpay.png";
import { Navbar } from "flowbite-react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const menuItem = [
  { id: 1, path: "", name: "Ödəniş et", icon: <IoWalletOutline /> },
  {
    path: "privateinfo",
    name: "Şəxsi məlumat",
    icon: <FaUserAlt />,
  },
  {
    path: "history",
    name: "Tarixçə",
    icon: <AiOutlineHistory />,
  },
  {
    path: "cards",
    name: "Kartlarım",
    icon: <IoCardSharp />,
  },
  {
    path: "bonuses",
    name: "Bonuslar",
    icon: <AiOutlinePercentage />,
  },
  {
    path: "templates",
    name: "Şablonlar",
    icon: <AiOutlineStar />,
  },
  {
    path: "logout",
    name: "Çıxış",
    icon: <IoLogOutOutline />,
  },
];
const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="box">
      <div className="sidebar hidden md:block">
        <Link to={"/"}>
          <div className="top_section mb-28">
            <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
             <img className="h-14" src={logo} alt="" />
            </h1>
            
          </div>
        </Link>

        <div>
          {menuItem.map((item, index) => (
            <div>
              <NavLink
                to={item.path}
                key={index}
                className="link flex flex-col"
                activeclassName="active"
              >
                <div className="flex items-center gap-3">
                  <div className="icon">{item.icon}</div>
                  <div
                    style={{ display: isOpen ? "block" : "none" }}
                    className="link_text text-sm lg:text-xl  "
                  >
                    {item.name}
                  </div>
                </div>
              </NavLink>

              <div className="w-full border-b border-[#e5e2e2] mt-2 mb-2"></div>
            </div>
          ))}
        </div>
      </div>

      <main>
        <div className="block md:hidden nav-mobile">
          <div className=" flex justify-between items-center  ">
            <div className="w-full">
              <div className="w-full py-8">
                <div className="flex justify-between items-center">
                <div>
                  <img className="h-10" src={logo} alt="logo" />
                </div>
                <div
                      className="HAMBURGER-ICON  space-y-2   cursor-pointer "
                      onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                      <span className="block h-0.5 w-6 animate-pulse bg-[#FAAF40]"></span>
                      <span className="block h-[3px] w-6 animate-pulse bg-[#FAAF40]"></span>
                      <span className="block h-0.5 w-6 animate-pulse bg-[#FAAF40]"></span>
                </div>

                </div>
                <nav>
                  <section className="MOBILE-MENU  flex lg:hidden">
                   

                    <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
                      <div
                        className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
                        onClick={() => setIsNavOpen(false)}
                      >
                        <svg
                          className="h-8 w-8 text-white cursor-pointer"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1="18" y1="6" x2="6" y2="18" />
                          <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                      </div>
                      <ul className="MENU-LINK-MOBILE-OPEN  transition-[0.2s] flex flex-col  justify-between min-h-[250px] mt-16">
                        <div>
                          {menuItem.map((item, index) => (
                            <div>
                              <NavLink
                                to={item.path}
                                key={index}
                                className="link flex flex-col"
                                activeclassName="active"
                                onClick={() => setIsNavOpen(false)}
                              >
                                <div className="flex items-center gap-3">
                                  <div className="icon-mobile text-2xl">
                                    {item.icon}
                                  </div>
                                  <div
                                    style={{
                                      display: isOpen ? "block" : "none",
                                    }}
                                    className="link_text text-white "
                                  >
                                    {item.name}
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          ))}
                        </div>
                      </ul>
                    </div>
                  </section>
                </nav>
                <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        transition:.3s;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: #4F2D7E;
        z-index: 10;
        display: flex;
        justify-content: space-evenly;
      }
    `}</style>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div>
              <form class="flex items-center">
               
              <div class="relative w-full">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <AiOutlineSearch className="text-[#C9C9C9] text-2xl"/>

                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        class=" focus:outline-none placeholder:font-normal placeholder:text-[#C9C9C9] bg-white border border-[#FBB040] text-gray-900 text-lg rounded-lg block w-full pl-12 px-28 py-[9px]  font-semibold "
                        placeholder="Axtar"
                        required
                      />
                    </div>
              </form>
            </div>
          </div>
        </div>

        <div className="hidden md:block nav-desktop">
          <nav className=" bg-white    py-5 rounded ">
            <div className="max-w-[75%] flex flex-wrap justify-between items-center mx-auto">
              <a href="h" class="flex items-center xl:text-3xl">
                <IoWalletOutline className="text-[#FBB040] mr-5" />{" "}
                <span>Ödəniş etmək</span>
              </a>

              {/* Login Modal */}

              <div
                className="justify-between items-center  md:flex  "
                id="mobile-menu-2"
              >
                <ul className="flex flex-col mt-4 md:flex-row  md:mt-0 md:text-sm md:font-medium">
                  <li>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex justify-center w-full   px-4 py-2 bg-white xl:text-lg font-medium ">
                          <IoIosNotificationsOutline
                            className=" h-7 w-7 mr-3 xl:text-3xl text-[#FBB040]"
                            aria-hidden="true"
                          />
                          Bildirişlər
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#s"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 xl:text-lg"
                                  )}
                                >
                                  RU
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#s"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 xl:text-lg"
                                  )}
                                >
                                  EN
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </li>
                  <li>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex justify-center w-full   px-4 py-2 bg-white text-lg font-medium ">
                          <HiOutlineGlobeAlt
                            className=" h-7 w-7 mr-3 text-[#FBB040]"
                            aria-hidden="true"
                          />
                          AZE
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#s"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-lg"
                                  )}
                                >
                                  RU
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#s"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-lg"
                                  )}
                                >
                                  EN
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </li>
                </ul>
              </div>

              <div className="w-full xl:w-auto">
                <div>
                  <form class="flex items-center">
                    
                    <div class="relative w-full">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <AiOutlineSearch className="text-[#C9C9C9] text-2xl"/>

                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        class=" focus:outline-none placeholder:font-normal placeholder:text-[#C9C9C9] bg-white border border-[#FBB040] text-gray-900 text-lg rounded-lg block w-full pl-12 px-28 py-[9px]  font-semibold "
                        placeholder="Axtar"
                        required
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </nav>
        </div>

        <Outlet />
      </main>
    </div>
  );
};

export default Sidebar;
