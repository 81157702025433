import React from "react";
import payt from "../../assets/payt.png"
const MobilePay1 = () => {
  return (
    <>
      <div>
        <div className="max-w-[90%] md:max-w-[50%] mx-auto bg-[#FAFAFA] shadow mt-10">
          <div className=" relative flex flex-col md:max-w-[75%] mx-auto  ">
            {/*header*/}

            <div className="flex items-center justify-center p-5 ">
              <div className="flex justify-center items-center">
                <h3 className="text-xl text-center font-bold">Bakcell</h3>
              </div>
            </div>
            <div>
              {" "}
              <h5 className="text-center text-[#2C2C2C] text-xl font-semibold">
              Ödəniş uğurla başa çatdı
              </h5>
            </div>
            {/*body*/}
            <div className="relative  px-6 flex flex-col">
              <div className="flex flex-col md:flex md:flex-rowjustify-between">
                <div>
                    <div className="flex gap-2 mb-8">
                    <span className="text-[#A1A1A1] text-md font-semibold">Xidmət:</span>
                    <p className="text-[#2C2C2C] font-semibold">Mobil operatorlar</p>
                    </div>
                   <div>
                    <label className="text-black text-md text-md font-semibold" >E mail</label>
                   <input
                  type="text"
                  className=" text-black rounded-lg px-4 mb-10 py-2 border border-[#DBDBDB] w-full placeholder:text-[#B1B1B1]"
                  placeholder="Yaz.."
                />
                   </div>
                </div>
                <div>
                    <div className="flex items-center justify-center order-1">
                        <img className="order-1" src={payt} alt="" />
                        <p className="bg-[#4F2D7E] text-white text-center py-8 px-8  shadow-lg rounded-lg text-3xl font-semibold order-1">1 Azn</p>
                    </div>
                </div>
              </div>

              <div className="flex flex-col md:flex md:flex-row md:items-center md:justify-center mb-1  p-6 ">
                <button
                  className="bg-transparent text-black font-semibold text-md px-6 py-1 rounded-xl  outline-none focus:outline-none mr-1 mb-2 ease-linear transition-all duration-150"
                  type="button"
                >
                  Ana səhifə
                </button>
                <button
                  className=" text-[#B3B3B3] md:w-28 bg-[#E4E4E4] font-semibold rounded-lg  text-md  py-2  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Qəbz göndər
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobilePay1;
