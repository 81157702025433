import React from "react";
import app from "../../assets/app store.png";
import master from "../../assets/master.png";
import visa from "../../assets/VISA.png";
import secondLogo from "../../assets/logo-kashlook.png";
import google from "../../assets/google play.png";
import ig from "../../assets/IG.png";
import fb from "../../assets/FB.png";
import ln from "../../assets/LINKEDIN.png";

const Footer = () => {
  return (
    <>
      {/* Footer */}
      <div className="bg-[#4F2D7E] w-full ">
        <div className="max-w-[80%] md:max-w-[60%] mx-auto   gap-3 md:grid md:grid-cols-3">
         
            <div className="py-6">
              <h3 className="text-white text-2xl md:text-3xl pb-6">Məlumat</h3>
              <ul>
                <li className="text-white text-lg  md:text-2xl py-1">
                  Təhlükəsizlik
                </li>
                <li className="text-white text-lg  md:text-2xl py-1">
                  Şirkət haqqında
                </li>
                <li className="text-white text-lg   md:text-2xl py-1">
                  Tez-tez verilən suallar
                </li>
              </ul>
            </div>
            <div className="py-6">
              <h3 className="text-white text-2xl md:text-3xl pb-6">Əlaqə</h3>
              <ul>
                <li className="text-white text-lg md:text-2xl py-1">
                  Əlaqə Mərkəzi
                </li>
                <li className="text-white text-lg  md:text-2xl py-1">
                  Ofis şəbəkəsi
                </li>
                <li className="py-[5px]">
                  <div className="flex gap-3 ">
                    <span>
                      <img src={ig} alt="" />
                    </span>
                    <span>
                      <img src={fb} alt="" />
                    </span>
                    <span>
                      <img src={ln} alt="" />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
         

          <div className="py-11">
            <div className="pb-6">
              <img src={secondLogo} alt="" />{" "}
            </div>
            <div className="py-4  flex  gap-2">
              <div>
                <img src={app} alt="" />
              </div>
              <div>
                <img src={google} alt="" />
              </div>
            </div>
            <div className="py-1 flex gap-2">
              <div>
                <img src={visa} alt="" />
              </div>
              <div className="mt-2">
                <img src={master} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
